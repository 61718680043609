import React from "react"
import {graphql} from "gatsby"
import {Badge, Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import {BouwtypeList} from "../components/Bouwtype";
import {Header} from "../components/Header";
import Situatie from "../components/Situatie";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {Project} from "gatsby-theme-nieuwbouw/src/graphqlTypes";


interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.markdownRemark.frontmatter.title} pageDescription={data.markdownRemark.frontmatter.description}>
            <Header
                image={data.project.hoofdfoto?.localFile?.childImageSharp?.gatsbyImageData}
            />
            <Container
                fluid={'lg'}
                className={'my-5 text-center text-md-left'}
            >
                <section
                    className={'my-5'}
                >
                    <BouwtypeList isHome={false} />
                </section>
                <section
                    className={'my-5'}
                >
                    <header>
                        <h2
                            className={'text-center'}
                        >
                            Situatie
                        </h2>
                    </header>
                    <Situatie />
                </section>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    markdownRemark: IPage
    project: Project
}

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "woningen"}}) {
            ...Page
        }
        project {
            hoofdfoto {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`

